import React, { useState } from "react";
import SearchIcon from "../icons/SearchIcon";
import PropTypes from "prop-types";

export default function CustomSearch({
  width,
  onchangeFun,
  loader,
  placeHolder = "",
  lable
}) {
  const [searchKey, setsearchKey] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    onchangeFun(searchKey);
  };
  return (
    <>
    <form onSubmit={handleSubmit} className="relative">
      <input
        disabled={loader.status === "loading" ? true : false}
        onChange={(e) => setsearchKey(e.target.value)}
        className="pr-10"
        style={{ width: width }}
        placeholder={placeHolder}
      />
      <button className="absolute right-4 top-3 submit_searchBtn">
        <SearchIcon />
      </button>
    </form>
      <label className="mt-3 text-[12px] text-blue-500" >{lable}</label>
      </>
  );
}
CustomSearch.propTypes = {
  width: PropTypes.string,
  lable: PropTypes.string,
  placeHolder: PropTypes.string,
  onchangeFun: PropTypes.func,
  loader: PropTypes.object,
};
