import React from "react";
import PropTypes from "prop-types";
import VideoIcon from "../../icons/VideoIcon";
import DocumentIcon from "../../icons/DocumentIcon";

export default function LesonPrompetCard({ leson }) {
  return (
    <div className="w-full ">
      <div className="my_rounded bg_greay items-center m-auto flex p-4 w-fit mb-4">
        <h4 className="">{leson.name}</h4>
        {leson.videoExists && (
          <div className="mx-1">
            <VideoIcon iconColor="greay" />
          </div>
        )}
        {leson.pdfExists && (
          <div className="mx-1">
            <DocumentIcon iconColor="greay" />
          </div>
        )}
      </div>
    </div>
  );
}
LesonPrompetCard.propTypes = {
  leson: PropTypes.object,
};
