import React, { useState } from "react";
import PropTypes from "prop-types";
import { myFeach, parceErr } from "../../../globalFunctions/Api";
import SucsessMessage from "../../reuseables/SucsessMessage";
import CustomSubmitButton from "../../reuseables/CustomSubmitButton";
import BlockIcon from "../../icons/BlockIcon";
import RemoveBlockIcon from "../../icons/removeBlockIcon";
export default function BlouckStudentModal({
  close,
  regetStudent,
  studentId,
  studentName,
  currentStatus,
}) {
  const [loader, setloader] = useState({ status: "" });
  const [submitErr, setsubmitErr] = useState();
  const [openFeedBackMessage, setopenFeedBackMessage] = useState({
    status: false,
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    setloader({ status: "loading" });
    myFeach(`students/${studentId}`, "PATCH", {}, "token")
      .then(() => {
        let message =
          currentStatus == "blocked" ? "تم إلغاء حظر الطالب" : "تم حظر الطالب";
        setopenFeedBackMessage({
          status: true,
          isSucsess: true,
          message: message,
        });
        setloader({ status: "done" });
        setTimeout(() => {
          close();
        }, 1000);
        regetStudent();
      })
      .catch((err) => {
        let message =
          currentStatus == "blocked"
            ? "فشل إلغاء حظر الطالب"
            : "فشل حظر الطالب";
        setloader({ status: "failed" });
        let errtext = parceErr(err)?.error ? parceErr(err)?.error : message;
        setopenFeedBackMessage({
          status: true,
          message: errtext,
        });
        setsubmitErr(errtext);
      });
  };
  return (
    <>
      {openFeedBackMessage.status ? (
        <SucsessMessage
          close={() => setopenFeedBackMessage({ status: false })}
          isSucsess={openFeedBackMessage?.isSucsess}
          message={openFeedBackMessage?.message}
        />
      ) : (
        <div>
          <button onClick={close} className="modal_bg"></button>
          <div className="modal_content p-10 flex flex-col items-center">
            {currentStatus == "blocked" ? (
              <div className="flex items-center p-2 mb-8">
                <RemoveBlockIcon />
                <h3>هل ترغب في إلغاء حظر الطالب {studentName} ؟</h3>
              </div>
            ) : (
              <div className="flex items-center p-2 mb-8 ">
                <BlockIcon />
                <h3 className="text-red-500 mx-2">
                  هل ترغب في حظر الطالب {studentName} ؟
                </h3>
              </div>
            )}
            <form
              className="border_top_greay pt-6 pb-4 w-full flex justify-center "
              onSubmit={handleSubmit}
            >
              <CustomSubmitButton
                loader={loader}
                err={submitErr}
                width="112px"
                height="32px"
                title="تأكيد"
              />
              <button
                type="button"
                className="mx-2 w-28 h-8 gradient_border"
                onClick={close}
              >
                إلغاء
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
}
BlouckStudentModal.propTypes = {
  close: PropTypes.func,
  regetStudent: PropTypes.func,
  studentId: PropTypes.string,
  studentName: PropTypes.string,
  currentStatus: PropTypes.string,
};
