export function cutString(str, limit) {
  if (str?.length <= limit) {
    return str;
  } else {
    return str ? str?.slice(0, limit) + "..." : "";
  }
}
export function formatDateTime(dateTime) {
  const date = new Date(dateTime);
  const optionsDate = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const optionsTime = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formattedDate = date.toLocaleDateString("ar-EG", optionsDate);
  const formattedTime = date.toLocaleTimeString("ar-EG", optionsTime);

  return { date: formattedDate, time: formattedTime };
}

export function convertToDate(dateString) {
  if (dateString) {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("ar-EG", options);
  } else {
    return "-----";
  }
}
