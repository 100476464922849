import React, { useRef, useState } from "react";
import OtpIcon from "../../components/icons/OtpIcon";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import CustomSubmitButton from "../../components/reuseables/CustomSubmitButton";
import { checkRequired } from "../../globalFunctions/validation";
import SucsessMessage from "../../components/reuseables/SucsessMessage";
import { myFeach, parceErr } from "../../globalFunctions/Api";
import Cookies from "js-cookie";
export default function OTP() {
  const navigate = useNavigate();
  const prames = useParams();
  const inputRefs = useRef([]);
  const [inputValues, setInputValues] = useState(["", "", "", ""]);
  const [isResendBtnDisabled, setisResendBtnDisabled] = useState(true);
  const [timer, setTimer] = useState(10 * 60);
  const [formData, setformData] = useState({
    data: { first: "", second: "", thired: "", fourth: "" },
    submitLoader: { status: "" },
    errors: {},
  });
  const [openFeedBackMessage, setopenFeedBackMessage] = useState({
    status: false,
  });
  const [resendOtpLoader, setresendOtpLoader] = useState({ status: "" });
  const handleChange = (event, index) => {
    const value = event.target.value;
    const newInputValues = [...inputValues];
    newInputValues[index] = value.length > 1 ? value.slice(0, 1) : value;
    setInputValues(newInputValues);
    if (value.length === 1 && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };
  const handleResendCode = (method) => {
    setresendOtpLoader({ status: "loading" });
    let baseUrl = `${window.location.pathname.includes("reset") ? "forgot-password" : "signup"}-resend-verification-code`;
    let tokenName = window.location.pathname.includes("reset")
      ? "forgotPasswordToken"
      : "signupToken";
    myFeach(`${baseUrl}/${method}`, "POST", {}, tokenName)
      .then(({ data }) => {
        setresendOtpLoader({ status: "done" });
        Cookies.set(`${tokenName}`, data[tokenName]);
        setopenFeedBackMessage({
          status: true,
          message: "تم إرسال الكود بنجاح",
          isSucsess: true,
        });
        setisResendBtnDisabled(true);
      })
      .catch((err) => {
        setresendOtpLoader({ status: "done" });
        const myErr = JSON.parse(err.message);
        setopenFeedBackMessage({ status: true, message: myErr.error });
      });
  };
  // +201000673448
  // Admin@123
  const handleSigenUp = (codeObj) => {
    myFeach("verify-phone", "PUT", codeObj, "signupToken")
      .then(({ data }) => {
        setformData({
          ...formData,
          submitLoader: { status: "" },
          errors: {},
          error: "",
        });
        setopenFeedBackMessage({
          status: true,
          message: "كود صحيح تم التسجيل بنجاح",
          isSucsess: true,
        });
        Cookies.remove("signupToken");
        Cookies.set("token", data.loginToken);
        setTimeout(() => {
          navigate("/complete-information");
        }, 2000);
      })
      .catch((err) => {
        setformData({
          ...formData,
          loader: { status: "" },
          error: parceErr(err).error
            ? parceErr(err).error
            : "فشل إرسال الكود حاول مرة أخرى",
        });
        setopenFeedBackMessage({ status: true, message: parceErr(err).error });
      });
  };
  const handleResetPassword = (codeObj) => {
    myFeach("verify-forgot-password", "POST", codeObj, "forgotPasswordToken")
      .then(({ data }) => {
        setformData({
          ...formData,
          submitLoader: { status: "" },
          errors: {},
          error: "",
        });
        setopenFeedBackMessage({
          status: true,
          message: "كود صحيح ",
          isSucsess: true,
        });
        Cookies.set("resetPasswordToken", data?.resetPasswordToken);
        setTimeout(() => {
          navigate("/reset-password");
        }, 2000);
      })
      .catch((err) => {
        console.log(parceErr(err));
        setopenFeedBackMessage({ status: true, message: parceErr(err).error });
        setformData({
          ...formData,
          loader: { status: "" },
          error: parceErr(err).error
            ? parceErr(err).error
            : "فشل إرسال الكود حاول مرة أخرى",
        });
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let requiredErrs = checkRequired(
      formData.data,
      ["first", "second", "thired", "fourth"],
      formData,
      setformData
    );
    if (requiredErrs === "sucsess") {
      setformData({
        ...formData,
        errors: {},
        submitLoader: { status: "loading" },
      });
      let codeObj = {
        verificationCode: inputValues.join(""),
      };
      if (prames.type === "signup") {
        handleSigenUp(codeObj);
      } else {
        handleResetPassword(codeObj);
      }
    }
  };
  useEffect(() => {
    setformData({
      ...formData,
      data: {
        first: inputValues[0],
        second: inputValues[1],
        thired: inputValues[2],
        fourth: inputValues[3],
      },
    });
  }, [inputValues]);
  useEffect(() => {
    if (isResendBtnDisabled) {
      setTimeout(
        () => {
          setisResendBtnDisabled(false);
        },
        2 * 60 * 1000
      );
    }
    return () => clearTimeout(timer);
  }, [isResendBtnDisabled]);
  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 0) {
          clearInterval(countdown);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
    return () => {
      clearInterval(countdown);
    };
  }, []);
  return (
    <main className="full_page bg_primary ">
      {openFeedBackMessage.status && (
        <SucsessMessage
          close={() => setopenFeedBackMessage({ status: false })}
          isSucsess={openFeedBackMessage?.isSucsess}
          message={openFeedBackMessage?.message}
        />
      )}
      <form
        onSubmit={handleSubmit}
        className="m-auto w-10/12 md:w-4/12 bg_greay large_rounded p-10  mt-4 flex flex-col items-center"
      >
        <OtpIcon />
        <h4 className="mt-8">
          لقد تم إرسال كود مكون من 4 أرقام على رقم الهاتف
        </h4>
        <h4 className="font-bold mt-2 direction_left">{prames.phone}</h4>
        <div className="flex w-48 justify-between direction_left mt-10">
          {inputValues.map((value, index) => (
            <input
              key={index}
              // disabled={index == 0 || inputValues[index - 1] ? false : true}
              className={`text-center ${formData.errors[names[index]] ? "danger_border" : ""} `}
              style={{ width: "40px" }}
              ref={(ref) => (inputRefs.current[index] = ref)}
              type="text"
              value={value}
              onChange={(event) => handleChange(event, index)}
            />
          ))}
        </div>
        <div className="w-full justify-center flex mt-4 ">
          {timer > 0 && <h4>صلاحية الكود ستنتهي خلال</h4>}
          <h4 className="w-12 font-bold text-left">{formatTime(timer)}</h4>
        </div>
        <div className="flex justify-center w-full items-center  mt-4 ">
          {timer > 0 ? (
            <span>لم تستقبل الكود عبر واتساب؟</span>
          ) : (
            <span className="text_danger mb-4">انتهت صلاحية الكود</span>
          )}
        </div>
        {timer > 0 && (
          <div className="flex mb-10 h-9 items-center ">
            {resendOtpLoader.status === "loading" ? (
              <div className="dot_loader "></div>
            ) : (
              <>
                <button
                  type="button"
                  disabled={isResendBtnDisabled}
                  className="text_primary mx-2 block "
                  onClick={() => handleResendCode("whatsapp")}
                >
                  إعادة إرسال
                </button>
                <h4>أو</h4>
                <button
                  disabled
                  type="button"
                  className="text_primary mx-2 block"
                  onClick={() => handleResendCode("sms")}
                >
                  إرسال عبر sms
                </button>
              </>
            )}
          </div>
        )}
        <CustomSubmitButton
          loader={formData.submitLoader}
          err={formData?.error}
          width="100%"
          title="تأكيد"
        />
      </form>
    </main>
  );
}
const names = ["first", "second", "thired", "fourth"];
const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};
