import React, { useState } from "react";
import PropTypes from "prop-types";

import CustomSubmitButton from "../../reuseables/CustomSubmitButton";
import SucsessMessage from "../../reuseables/SucsessMessage";
import { myFeach, parceErr } from "../../../globalFunctions/Api";
import DeleteIcon from "../../icons/DeleteIcon";

export default function DetailedDeletePrompet({
  close,
  afterDeleteFunction,
  apiUrl,
  message,
  children,
  apiMethod,
}) {
  // const prams = useParams();
  const [loader, setloader] = useState({ status: "" });
  const [submitErr, setsubmitErr] = useState();
  const [openFeedBackMessage, setopenFeedBackMessage] = useState({
    status: false,
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    setloader({ status: "loading" });
    setsubmitErr("");
    myFeach(apiUrl, apiMethod, {}, "token")
      .then(() => {
        setloader({ status: "done" });
        setopenFeedBackMessage({
          status: true,
          isSucsess: true,
          message: message.sucsess,
        });

        setTimeout(() => {
          afterDeleteFunction();
          close();
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
        setloader({ status: "failed" });
        setsubmitErr(
          parceErr(err)?.error ? parceErr(err).error : message.failed
        );
        setopenFeedBackMessage({
          status: true,
          message: parceErr(err)?.error ? parceErr(err).error : message.failed,
        });
      });
  };
  return (
    <>
      {openFeedBackMessage.status && (
        <SucsessMessage
          close={() => setopenFeedBackMessage({ status: false })}
          isSucsess={openFeedBackMessage?.isSucsess}
          message={openFeedBackMessage?.message}
        />
      )}
      <div className="">
        <button
          onClick={close}
          className="modal_bg h-screen overflow-hidden"
        ></button>
        <div className="modal_content ">
          <div className="flex w-full justify-center items-center  p-10 ">
            {apiMethod == "DELETE" && (
              <div className="p-2 circle_rounded bg_danger">
                <DeleteIcon stroke={"white"} />
              </div>
            )}
            <h4 className={`font-bold mx-2 text-${message.prompet?.color}-500 `}>
              {message.prompet?.text}؟
            </h4>
          </div>

          {children}
          <form
            className="border_top_greay pt-10 pb-4 w-full  justify-center min-h-[120px] "
            onSubmit={handleSubmit}
          >
            <div className="flex m-auto w-fit">
              <CustomSubmitButton
                loader={loader}
                // err={submitErr}
                width="112px"
                height="32px"
                title="تأكيد"
              />
              <button
                type="button"
                className="mx-2 w-28 h-8 gradient_border"
                onClick={close}
              >
                إلغاء
              </button>
            </div>

            <h6 className="text-red-500 mx-auto w-fit">{submitErr}</h6>
          </form>
        </div>
      </div>
    </>
  );
}
DetailedDeletePrompet.propTypes = {
  close: PropTypes.func,
  afterDeleteFunction: PropTypes.func,
  apiMethod: PropTypes.string,
  apiUrl: PropTypes.string,
  children: PropTypes.any,
  message: PropTypes.object,
};

