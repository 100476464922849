import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import AddVideoIcon from "../icons/AddVideoIcon";
import InputScaleable from "./InputScaleable";
import { myFeach, parceErr } from "../../globalFunctions/Api";
import { cutString } from "../../globalFunctions/TextModify";
import RightIcon from "../icons/RightIcon";
import LoaderModal from "./LoaderModal";
import DeleteIcon from "../icons/DeleteIcon";
import SucsessMessage from "./SucsessMessage";
import DeleteFileConfirm from "../pagesComponents/courses/DeleteFileConfirm";
export default function CustomUplodeVideo({
  containerClass,
  lable,
  required,
  disabled,
  formData,
  setformData,
  onChange,
  name,
  getOldDataLoader,
  courseId,
  oldVideo,
}) {
  const inputRef = useRef(null);
  const [loader, setloader] = useState({
    status: "",
    text: "",
  });
  const [openConfirmDeleteModal, setopenConfirmDeleteModal] = useState(false);
  const [deleteVideoLoader, setdeleteVideoLoader] = useState({
    status: "",
    text: "",
  });
  const [openDeleteFeedBack, setopenDeleteFeedBack] = useState({
    status: false,
    isSucsess: true,
  });
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const handleFileChange = (event) => {
    setloader({ status: "loading" });
    let file = event.target.files[0];
    let SizeInMB = file.size / 1024 / 1024;
    if (SizeInMB < 300) {
      if (validateVideoFile(file)) {
        myFeach(
          `lessons/generate-path?filename=${file.name}&contentType=${file.type}&courseId=${courseId}&size=${SizeInMB}`,
          "POST",
          {},
          "token"
        )
          .then(({ data }) => {
            inputRef.current.value = "";
            handleUpload(data.path, file);
          })
          .catch((err) => {
            let errText = parceErr(err)?.error
              ? parceErr(err).error
              : "فشل رفع الفيديو";
            setloader({ status: "failed", text: errText });
          });
      } else {
        setloader({
          status: "failed",
          text: "مسموح برفع الفيديو بصيغة mp4 فقط",
        });
      }
    } else {
      setloader({
        status: "failed",
        text: ` هذا الفيديو حجمه ${Math.round(SizeInMB * 100) / 100} MB  قم برفع الفيديو بحجم أقصى 300 MB `,
      });
    }
  };
  const handleUpload = async (pathName, file) => {
    try {
      const response = new XMLHttpRequest();
      response.open("PUT", pathName, true);
      response.setRequestHeader("Content-Type", file.type);
      response.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentCompleted = Math.round(
            (event.loaded * 100) / event.total
          );
          setUploadPercentage(percentCompleted);
        }
      };
      response.onload = () => {
        if (response.status === 200) {
          onChange(response.responseURL);
          setloader({
            status: "done",
            text: `تم رفع الفيديو: ${cutString(file.name, 15)}`,
          });
          setUploadPercentage(0);
        } else {
          setloader({ status: "failed", text: "فشل رفع الفيديو" });
        }
      };
      response.onerror = () => {
        setloader({ status: "failed", text: "فشل رفع الفيديو" });
      };
      response.send(file);
    } catch (error) {
      setloader({ status: "failed", text: "فشل رفع الفيديو" });
    }
  };
  const handleDeleteVideo = () => {
    if (oldVideo == formData.data[name]) {
      // when video is related with leson should delete from back-end only
      setformData({ ...formData, data: { ...formData.data, [name]: "" } });
      setopenDeleteFeedBack({
        status: true,
        isSucsess: true,
        message: "تم مسح الفيديو بنجاح",
      });
      setopenConfirmDeleteModal(false);
    } else {
      setdeleteVideoLoader({ status: "loading" });
      const body = { url: formData.data[name] };
      myFeach("lessons/delete-video", "PUT", body, "token")
        .then(() => {
          setdeleteVideoLoader({ status: "done" });
          setloader({ status: "" });
          setformData({ ...formData, data: { ...formData.data, [name]: "" } });
          setopenDeleteFeedBack({
            status: true,
            isSucsess: true,
            message: "تم مسح الفيديو بنجاح",
          });
          setopenConfirmDeleteModal(false);
        })
        .catch((err) => {
          let textErr = parceErr(err)?.error
            ? parceErr(err)?.error
            : "فشل حذف الفيديو";
          setdeleteVideoLoader({
            status: "failed",
            text: cutString(textErr, 25),
          });
          setopenDeleteFeedBack({
            status: true,
            isSucsess: false,
            message: cutString(textErr, 25),
          });
        });
    }
  };
  useEffect(() => {
    if (formData.data[name]) {
      setloader({
        status: "old",
        text: `تم رفع الفيديو: ${cutString(formData.data[name], 15)}`,
      });
    }
    if (formData?.errors[name]) {
      setloader({
        status: "failed",
        text: cutString(formData.errors[name], 15),
      });
    }
  }, [getOldDataLoader]);
  useEffect(() => {
    if (!formData.data[name]) {
      setloader({ status: "" });
    }
  }, [formData.data[name]]);
  return (
    <>
      {openDeleteFeedBack.status && (
        <SucsessMessage
          close={() => setopenDeleteFeedBack({ status: false })}
          isSucsess={openDeleteFeedBack.isSucsess}
          message={openDeleteFeedBack.message}
        />
      )}
      {loader.status === "loading" && (
        <LoaderModal
          loaderPrecentage={uploadPercentage}
          lable="يتم رفع الفيديو"
        />
      )}
      {openConfirmDeleteModal && (
        <DeleteFileConfirm
          loader={deleteVideoLoader}
          confirm={handleDeleteVideo}
          close={() => setopenConfirmDeleteModal(false)}
        />
      )}
      {getOldDataLoader === "done" ? (
        <div
          className={`mb-10 ${containerClass} ${disabled ? "opacity-80" : ""} `}
        >
          <label className="mb-5 block font-bold text-sm ">
            {lable} {required && <span className="text_danger">*</span>}{" "}
          </label>
          <div className="flex items-center  ">
            {(loader?.status == "" || loader?.status === "failed") && (
              <button
                disabled={loader?.status === "loading" || formData.data[name]}
                type="button"
                onClick={() => inputRef.current.click()}
                className={`w-44 h-28 my_rounded flex items-center justify-center ml-4 bg-white  ${loader?.status === "failed" ? "danger_border" : ""}`}
              >
                {loader?.status == "loading" && (
                  <div className="w-8 h-8 flex items-center justify-center">
                    <div className="loader"></div>
                  </div>
                )}
                <AddVideoIcon />
              </button>
            )}
            {loader.status === "loading" && (
              <div className="w-44 h-28 my_rounded flex items-center justify-center ml-4 bg-white">
                <div className="loader"></div>
              </div>
            )}
            {loader?.status === "old" && (
              <div className="w-44 h-28 my_rounded flex items-center justify-center ml-4 bg-white">
                <div>
                  <video controls>
                    <source src={formData.data[name]} />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            )}
            {loader?.status === "done" && (
              <div className="w-44 h-28 my_rounded flex items-center justify-center ml-4 bg-white">
                <div>
                  <div className="w-8 h-8 bg-green-400 circle_rounded flex items-center justify-center m-auto">
                    <RightIcon />
                  </div>
                  <h6 className="text-center">
                    {cutString(formData.data[name], 20)}
                  </h6>
                </div>
              </div>
            )}
            <div className="flex flex-col">
              <h4>قم برفع فيديو بصيغة mp4 بحد أقصى 300 MB</h4>
            </div>
            <input
              ref={inputRef}
              disabled={disabled}
              onChange={handleFileChange}
              type="file"
              className="display_none"
            />
          </div>
          {formData.data[name] && (
            <button
              disabled={deleteVideoLoader === "loading" ? true : false}
              type="button"
              className="mt-4"
              onClick={() => setopenConfirmDeleteModal(true)}
            >
              <div className="flex hover:text-red-500 items-center ">
                <h6 className="ml-2 text-xs ">
                  لرفع فيديو جديد قم بمسح القديم
                </h6>
                <DeleteIcon />
              </div>
            </button>
          )}
          {loader?.status === "failed" && (
            <h4 className="text-xs text_danger mt-2">{loader.text}</h4>
          )}
        </div>
      ) : (
        <InputScaleable width="70%" height="40px" />
      )}
    </>
  );
}
CustomUplodeVideo.propTypes = {
  lable: PropTypes.string,
  btn_class: PropTypes.string,
  containerClass: PropTypes.string,
  courseId: PropTypes.string,
  oldVideo: PropTypes.any,
  name: PropTypes.string,
  formData: PropTypes.object,
  onChange: PropTypes.func,
  setformData: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  getOldDataLoader: PropTypes.string,
};
let validateVideoFile = (file) => {
  let flag = "";
  const allowedTypes = [
    "video/mp4",
    "video/mpeg",
    "video/x-msvideo",
    "video/x-ms-wmv",
    "video/quicktime",
    "video/x-flv",
    "video/webm",
    "video/3gpp",
    "video/quicktime", // .mov
    "video/avi", // .avi
    "video/x-matroska", // .mkv
];

  if (allowedTypes.includes(file.type)) {
    flag = true;
  } else {
    flag = false;
  }
  return flag;
};
