import React, { useState } from "react";
import PropTypes from "prop-types";
import DeleteIcon from "../../icons/DeleteIcon";
import CustomSubmitButton from "../../reuseables/CustomSubmitButton";
import { myFeach, parceErr } from "../../../globalFunctions/Api";
import ResultMessage from "../../reuseables/ResultMessage";
import SucsessMessage from "../../reuseables/SucsessMessage";

export default function DeleteStudentsConfirmModal({
  students,
  close,
  regetStudents,
  handleMakeSelectedEmpty,
}) {
  const [loader, setloader] = useState({ status: "" });
  const [submitErr, setsubmitErr] = useState();
  const [openFeedBackMessage, setopenFeedBackMessage] = useState({
    status: false,
  });
  const handleDeleteStudents = (e) => {
    e.preventDefault();
    setloader({ status: "loading" });
    let url = `students?students=${students.map((elem) => elem.id).join(",")}`;
    myFeach(url, "DELETE", {}, "token")
      .then(() => {
        setopenFeedBackMessage({
          status: true,
          isSucsess: true,
          message: "تم الحذف  بنجاح",
        });
        setTimeout(() => {
          handleMakeSelectedEmpty();
          regetStudents();
          close();
        }, 1500);
        setloader({ status: "done" });
      })
      .catch((err) => {
        setloader({ status: "failed" });
        setsubmitErr(
          parceErr(err)?.error ? parceErr(err).error : "فشل الحذف حاول مرة أخرى"
        );
      });
  };
  return (
    <>
      {openFeedBackMessage.status == true && (
        <SucsessMessage
          close={() => setopenFeedBackMessage({ status: false })}
          isSucsess={openFeedBackMessage?.isSucsess}
          message={openFeedBackMessage?.message}
        />
      )}
      <div>
        <button onClick={close} className="modal_bg"></button>
        <div className="modal_content p-10 ">
          <div className="w-fit flex items-center m-auto my-4 ">
            <div className="p-3 circle_rounded bg-red-500 mx-3">
              <DeleteIcon stroke="white" />
            </div>
            <h3>هل أنت متأكد من حذف هؤلاء الطلبة</h3>
          </div>
          {students.length ? (
            <ul className="flex flex-col mx-3 my-4 max-h-80 overflow-y-scroll ">
              {students.map((elem, index) => (
                <li key={index}>
                  <span>{index + 1}.</span>
                  <span>{elem.name}</span>
                </li>
              ))}
            </ul>
          ) : (
            <ResultMessage isModal={true} message="قم باختيار الطلبة أولاً" />
          )}
          <div className="w-fit m-auto">
            <form
              className="flex items-start w-full"
              onSubmit={handleDeleteStudents}
            >
              <CustomSubmitButton
                loader={loader}
                err={submitErr}
                disabled={!students.length ? true : false}
                width="112px"
                height="32px"
                title="تأكيد"
              />
              <button
                type="button"
                className="mx-2 w-28 h-8 gradient_border"
                onClick={close}
              >
                إلغاء
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
DeleteStudentsConfirmModal.propTypes = {
  students: PropTypes.array,
  close: PropTypes.func,
  regetStudents: PropTypes.func,
  handleMakeSelectedEmpty: PropTypes.func,
};
