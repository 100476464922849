import React, { useEffect, useState } from "react";
import CustomTable from "../../components/pagesComponents/courses/CustomTable";
import { convertToDate } from "../../globalFunctions/TextModify";
import { myFeach, parceErr } from "../../globalFunctions/Api";
import CustomSearch from "../../components/reuseables/CustomSearch";
import ResultMessage from "../../components/reuseables/ResultMessage";
import SelectFilter from "../../components/reuseables/SelectFilter";
import Pagenation from "../../components/reuseables/Pagenation";
import DeleteStudentsConfirmModal from "../../components/pagesComponents/students/DeleteStudentsConfirmModal";
import { Link } from "react-router-dom";
export default function Students() {
  const [studentList, setstudentList] = useState({
    loader: { status: "loading" },
    list: [],
    numberOfPages: "",
    totalResult: "",
  });
  const [coursesList, setcoursesList] = useState({
    list: [],
    loader: { status: "", text: "" },
  });
  const [sectionList, setsectionList] = useState({
    list: [],
    loader: { status: "", text: "" },
  });
  const [activeCourse, setactiveCourse] = useState();
  const [activeSection, setactiveSection] = useState();
  const [page, setpage] = useState(1);
  const [searchKey, setsearchKey] = useState("");
  const [selectedStudents, setselectedStudents] = useState([]);
  const [openDeleteModal, setopenDeleteModal] = useState({ status: false });
  const handleSelectStudent = (student) => {
    let idsArray = selectedStudents.map((elem) => elem.id);
    {
      if (idsArray.includes(student.id)) {
        setselectedStudents(
          selectedStudents.filter((elem) => elem.id != student.id)
        );
      } else {
        setselectedStudents([...selectedStudents, student]);
      }
    }
  };
  const handleGetStudents = () => {
    if (studentList.loader.status !== "loading") {
      setstudentList({ ...studentList, loader: { status: "loading" } });
    }
    let apiUrl = `students?page=${page}${searchKey ? `&searchKey=${searchKey}` : ""}${activeCourse ? `&courses=${activeCourse}` : ""}${activeSection ? `&sections=${activeSection}` : ""}&limit=10`;
    myFeach(apiUrl, "GET", {}, "token")
      .then(({ data }) => {
        if (data.students.length) {
          setstudentList({
            loader: { status: "done" },
            list: data.students,
            numberOfPages: roundNumber(data.totalResults, 10),
            totalResult: data.totalResults,
          });
        } else {
          setstudentList({
            loader: { status: "failed", text: "لا يوجد نتايج" },
            list: data.students,
          });
        }
      })
      .catch((err) => {
        setstudentList({
          loader: {
            status: "failed",
            text: parceErr(err)?.error
              ? parceErr(err)?.error
              : "فشل تحميل الطلاب",
          },
        });
      });
  };
  const handleGetSections = () => {
    if (sectionList.loader.status !== "loading") {
      setsectionList({ ...sectionList, loader: { status: "loading" } });
    }
    let apiUrl = `sections/course/${activeCourse}`;
    myFeach(apiUrl, "GET", {}, "token")
      .then(({ data }) => {
        if (data.sections.length) {
          setsectionList({
            loader: { status: "done" },
            list: data.sections,
          });
        } else {
          setsectionList({
            loader: { status: "failed", text: "لا يوجد نتايج" },
          });
        }
      })
      .catch((err) => {
        setsectionList({
          loader: {
            status: "failed",
            text: parceErr(err)?.error
              ? parceErr(err)?.error
              : "فشل تحميل الطلاب",
          },
        });
      });
  };
  const handleGetCourses = () => {
    if (coursesList.loader.status !== "loading") {
      setcoursesList({ ...coursesList, loader: { status: "loading" } });
    }
    let apiUrl = `courses`;
    myFeach(apiUrl, "GET", {}, "token")
      .then(({ data }) => {
        if (activeSection) {
          setactiveSection("");
        }
        if (data.courses.length) {
          setcoursesList({
            loader: { status: "done" },
            list: data.courses,
          });
        } else {
          setcoursesList({
            loader: { status: "failed", text: "لا يوجد نتايج" },
          });
        }
      })
      .catch((err) => {
        setcoursesList({
          loader: {
            status: "failed",
            text: parceErr(err)?.error
              ? parceErr(err)?.error
              : "فشل تحميل الطلاب",
          },
        });
      });
  };
  useEffect(() => {
    if (studentList.loader.status !== "loading") {
      page == 1 ? handleGetStudents() : setpage(1);
    }
  }, [searchKey, activeCourse, activeSection]);
  useEffect(() => {
    handleGetStudents();
  }, [page]);
  useEffect(() => {
    handleGetCourses();
  }, []);
  useEffect(() => {
    activeCourse && handleGetSections();
  }, [activeCourse]);
  return (
    <main className="">
      {openDeleteModal.status === true && (
        <DeleteStudentsConfirmModal
          regetStudents={handleGetStudents}
          close={() => setopenDeleteModal({ status: false })}
          students={selectedStudents}
          handleMakeSelectedEmpty={() => setselectedStudents([])}
        />
      )}
      <div className="flex w-full justify-between flex-wrap mb-2 ">
        <div>
          <CustomSearch
            width="250px"
            loader={studentList.loader}
            placeHolder="ابحث بالاسم أو رقم الجوال"
            onchangeFun={(key) => setsearchKey(key)}
            lable={
              "يمكنك البحث عن الطلبة باستخدام بعض الحروف والأرقام ثم الضغط على enter "
            }
          />
          <div className="flex flex-wrap items-center">
            <SelectFilter
              onchangeFun={(coursrId) => {
                setactiveCourse(coursrId);
                setactiveSection("");
              }}
              list={coursesList.list}
              loader={coursesList.loader}
              activeChoice={activeCourse}
              message="اختر أحد الكورسات"
              disabled={studentList.loader.status == "loading" ? true : false}
              width="250px"
            />
            {activeCourse && (
              <SelectFilter
                onchangeFun={(sectionId) => setactiveSection(sectionId)}
                list={sectionList.list}
                loader={sectionList.loader}
                activeChoice={activeSection}
                disabled={studentList.loader.status == "loading" ? true : false}
                message="اختر أحد الأقسام"
                width="250px"
              />
            )}
          </div>
        </div>

        <button
          onClick={() => setopenDeleteModal({ status: true })}
          className="delete_students_btn my_rounded h-fit"
        >
          حذف الطلبة
        </button>
      </div>
      {studentList.loader.status == "done" && (
        <>
          <CustomTable headers={headers}>
            {studentList?.list.map((student, index) => (
              <tr
                key={index}
                className={`${(index + 1) % 2 == 0 && "odd_row_bg"}`}
              >
                <td className="flex items-center relative pt-10">
                  <input
                    type="checkbox"
                    checked={selectedStudents
                      ?.map((elem) => elem.id)
                      .includes(student.id)}
                    className="mx-2 w-4 "
                    onChange={() => handleSelectStudent(student)}
                  />
                  <Link
                    className="text_hover_active"
                    to={`/student/${student.id}`}
                  >
                    {" "}
                    {student.name}
                  </Link>
                </td>
                <td>
                  {student?.courses.length ? (
                    <>
                      {student?.courses.map((elem, index) => (
                        <span key={index}>
                          {elem.name}
                          {index != student.courses.length - 1 && ","}
                        </span>
                      ))}
                    </>
                  ) : (
                    "-------------"
                  )}
                </td>
                <td>{convertToDate(student?.enrollmentDate)}</td>
                <td>{convertToDate(student?.lastActivity)}</td>
                {/* <td>{student.note}</td> */}
                <td>{student.email}</td>
                <td className="direction_left">{student.phone}</td>
                <td>{student.academicYear}</td>
                <td>{student.age}</td>
              </tr>
            ))}
          </CustomTable>
          <Pagenation
            page={page}
            setPage={setpage}
            lastPage={studentList.numberOfPages}
            disabled={studentList.loader.status === "loading" ? true : false}
            totalResult={studentList.totalResult}
            pageActualResult={studentList.list?.length}
            name="طالب"
          />
        </>
      )}
      {studentList.loader.status == "loading" && (
        <div className="w-full">
          <div className="my-3 bg-white h-14 w-full"></div>
          <div className="my-3 bg-white h-14 w-full"></div>
          <div className="my-3 bg-white h-14 w-full"></div>
          <div className="my-3 bg-white h-14 w-full"></div>
          <div className="my-3 bg-white h-14 w-full"></div>
          <div className="my-3 bg-white h-14 w-full"></div>
        </div>
      )}
      {studentList.loader.status === "failed" && (
        <ResultMessage message={studentList.loader.text} />
      )}
    </main>
  );
}
const headers = [
  { text: "الاسم", key: "name" },
  { text: "الكورسات", key: "courses" },
  { text: "تاريخ الاشتراك", key: "enrollmentDate" },
  { text: "آخر نشاط ", key: "enrollmentDate" },
  // { text: "ملحوظة", key: "note" },
  { text: "البريد الإلكتروني", key: "email" },
  { text: "رقم الجوال", key: "phone" },
  { text: "الصف الدراسي", key: "academicYear" },
  { text: "العمر", key: "age" },
];
function roundNumber(number, limit) {
  const result = number / limit;
  if (result < 1) {
    return 1;
  } else if (result % 1 !== 0) {
    return Math.ceil(result);
  } else {
    return result;
  }
}
