import React from "react";
import PropTypes from "prop-types";
import HideIcon from "./HideIcon";
import ShowIcon from "./ShowIcon";

export default function VisibilityIcon({ hiddin }) {
  return (
    <>{hiddin ? <HideIcon stroke="black" /> : <ShowIcon color="#4271ff" />}</>
  );
}
VisibilityIcon.propTypes = {
  hiddin: PropTypes.bool,
};
