import React, { useEffect, useState } from "react";
import { myFeach, parceErr } from "../../../globalFunctions/Api";
import ResultMessage from "../../reuseables/ResultMessage";
import DolarIcon from "../../icons/DolarIcon";
import StarageIcon from "../../icons/StarageIcon";
import studentsIcon from "../../../assets/students 1.png";
import coursesIcon from "../../../assets/book 1.png";
export default function PlanOverView() {
  const [planOverView, setplanOverView] = useState({
    loader: { status: "loading" },
    data: {},
  });
  const getOverView = () => {
    myFeach("overview", "GET", {}, "token")
      .then(({ data }) => {
        setplanOverView({ loader: { status: "done" }, data: data });
      })
      .catch((err) => {
        let textErr = parceErr(err)?.error
          ? parceErr(err)?.error
          : "فشل تحميل النظرة العامة على باقتك";
        setplanOverView({ loader: { status: "failed", text: textErr } });
      });
  };
  useEffect(() => {
    getOverView();
  }, []);
  return (
    <>
      {planOverView.loader.status === "loading" && <>{componentScalable()}</>}
      {planOverView.loader.status === "failed" && (
        <ResultMessage message={planOverView.loader.text} />
      )}
      {planOverView.loader.status === "done" && (
        <div className="w-full flex flex-wrap justify-between bg-white my_rounded p-6">
          <div className="md:w-6/12 w-full mb-6 md:mb-0 ">
            <div className="flex md:items-center">
              <DolarIcon />
              <h3 className="text_secondary font-bold mx-2">
                أنت الآن على نظام الباقة
                {planOverView.data.currentPlan?.name}
              </h3>
            </div>
            <div className="flex md:items-center mt-4">
              <StarageIcon />
              <h3 className="flex  mx-2">
                مساحتك التخزينية هي:
                <div className="w-[3px]" ></div>
                <div className="direction_left flex" >
                 {planOverView.data.storage?.total} GB
                </div>
              </h3>
            </div>
            <div className="w-8/12 bg_greay my_rounded h-3 mt-6">
              <div
                style={{
                  minWidth: "1%",
                  width: `${100 - (planOverView.data.storage.remaining / planOverView.data.storage.total) * 100}%`,
                }}
                className="bg-yellow-400 h-full my_rounded"
              ></div>
            </div>
            <h4 className="text_black mt-2">
              {" "}
              متبقي لديك{planOverView.data.storage.remaining} جيجا بايت من{" "}
              {planOverView.data.storage.total} جيجا بايت
            </h4>
          </div>
          <div className="flex justify-start">
            <div className="bg_greay my_rounded p-3 ml-4">
              <div className="flex items-start flex-wrap ">
                <img src={studentsIcon} className="w-10 h-auto block ml-2" />
                <div className="">
                  <h5 className="text_secondary font-bold ">
                    عدد الطلبة الكلي
                  </h5>
                  <h3 className=" text-emerald-300">
                    {planOverView.data.students.current}
                  </h3>
                </div>
              </div>
              <h6>
                متبقي لديك إمكانية اشتراك{" "}
                {planOverView.data?.students?.remaining} طالب{" "}
              </h6>
            </div>
            <div className="bg_greay my_rounded p-3">
              <div className="flex items-start flex-wrap ml-2 ">
                <img src={coursesIcon} className="w-10 h-auto block" />
                <div className="md:mr-2">
                  <h5 className="text_secondary font-bold ">
                    عدد الكورسات الكلي
                  </h5>
                  <h3 className=" text-emerald-300">
                    {planOverView.data?.courses?.current}
                  </h3>
                </div>
              </div>
              <h6>
                متبقي لديك إمكانية إنشاء {planOverView.data?.courses?.remaining}{" "}
                كورس{" "}
              </h6>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const componentScalable = () => {
  return (
    <div className="w-full  bg-white my_rounded flex flex-wrap justify-between p-6">
      <div className=" w-full md:w-2/5">
        <div className="w-full flex justify-between">
          <div className="w-1/12  h-8 bg_greay mt-2"></div>
          <div className="w-10/12 h-8 bg_greay mt-2"></div>
        </div>
        <div className="w-full h-8 bg_greay mt-2"></div>
        <div className="w-full h-8 bg_greay mt-2"></div>
      </div>
      <div className="md:w-2/5 w-full flex justify-between ">
        <div className="bg_greay w-2/5 my_rounded h-32 mt-6 md:mt-0"></div>
        <div className="bg_greay w-2/5 my_rounded h-32 mt-6 md:mt-0"></div>
      </div>
    </div>
  );
};
