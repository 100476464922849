import React from "react";
import PropTypes from "prop-types";

export default function CustomSubmitButton({
  width = "300px",
  height = "40px",
  title = "",
  loader = { status: "", text: "" },
  err = "",
  disabled = false,
}) {
  return (
    <div style={{ width: width }} className="min-h-[75px]" >
      <button
        disabled={
          loader.status === "loading" || disabled == true ? true : false
        }
        style={{ width: width, height: height }}
        className="bg_gradiant my_rounded"
      >
        {loader.status === "loading" ? <div className="loader"></div> : title}
      </button>
      <h6 className="text_danger w-full text-center mt-1 min-h-[25px]">{err}</h6>
    </div>
  );
}
CustomSubmitButton.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  err: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  loader: PropTypes.object,
};
