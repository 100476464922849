import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
// components
import AddSectionModal from "./AddSectionModal";
import DetailedDeletePrompet from "./DetailedDeletePrompet";
import LesonPrompetCard from "./LesonPrompetCard";
// icons
import ButtonArrow from "../../icons/ButtonArrow";
import TopArrow from "../../icons/TopArrow";
import ContentIcon from "../../icons/ContentIcon";
import EditIcon from "../../icons/EditIcon";
import VideoIcon from "../../icons/VideoIcon";
import DocumentIcon from "../../icons/DocumentIcon";
import DeleteIcon from "../../icons/DeleteIcon";
import VisibilityIcon from "../../icons/VisibilityIcon";
export default function CourseSection({
  sectionData,
  index,
  regetCourseDetailes,
}) {
  const prams = useParams();
  const [showLesons, setshowLesons] = useState(index == 0 ? true : false);
  const [openDeleteOrToggleConfirm, setopenDeleteOrToggleConfirm] = useState({
    status: false,
    targetStatus: "",
    type: "",
  });
  const [openEditSectionModal, setopenEditSectionModal] = useState({
    status: false,
    id: "",
  });
  const handleDeleteOrToggleSectionAndLeson = (data, type, text, baseUrl) => {
    let deletemessage = {
      prompet: { text: `هل ترغب في حذف هذا ${text}`, color: "red" },
      sucsess: `تم حذف ${text} بنجاح`,
      failed: `فشل حذف ${text} حاول مرة أخرى`,
    };
    let ShowTogglemessage = {
      prompet: {
        text: data.hidden
          ? `هل ترغب في إظهار هذا ${text}`
          : `هل ترغب في إخفاء هذا ${text}`,
        color: data.hidden ? "green" : "red",
      },
      sucsess: data.hidden
        ? `تم إظهار ${text} بنجاح`
        : `تم إخفاء ${text} بنجاح`,
      failed: `فشل تغير حالة ظهور ${text} حاول مرة أخرى`,
    };

    setopenDeleteOrToggleConfirm({
      status: true,
      cardData: data,
      apiUrl: `${baseUrl}/${data.id}`,
      apiMethod: type,
      message:
        type == "DELETE" ? { ...deletemessage } : { ...ShowTogglemessage },
    });
  };
  return (
    <>
      {openDeleteOrToggleConfirm.status && (
        <DetailedDeletePrompet
          close={() => setopenDeleteOrToggleConfirm({ status: false })}
          apiUrl={openDeleteOrToggleConfirm.apiUrl}
          apiMethod={openDeleteOrToggleConfirm.apiMethod}
          message={openDeleteOrToggleConfirm.message}
          afterDeleteFunction={regetCourseDetailes}
        >
          <LesonPrompetCard leson={openDeleteOrToggleConfirm.cardData} />
        </DetailedDeletePrompet>
      )}
      {openEditSectionModal.status === true && (
        <AddSectionModal
          regetCourseDetailes={regetCourseDetailes}
          close={() => setopenEditSectionModal({ status: false, id: "" })}
          sectionData={openEditSectionModal.sectionData}
        />
      )}
      <div className="w-full my_rounded my-10 ">
        <div className="bg_title top_rounded py-3 px-4 flex justify-between ">
          <h4 className="text_white"> القسم {index + 1}</h4>
          <button onClick={() => setshowLesons(!showLesons)}>
            {showLesons ? <TopArrow /> : <ButtonArrow />}
          </button>
        </div>
        {showLesons && (
          <div className="w-full">
            <div className="bg_greay w-full py-4 px-4 flex justify-between flex-wrap">
              <div className="flex items-center flex-wrap">
                <div className="flex items-center">
                  <ContentIcon />
                  <h5 className="mx-3 md:text-lg text-sm ">
                    {sectionData.name}
                  </h5>
                </div>
                <div className="flex items-center">
                  <button
                    className="my-2 md:my-0 flex "
                    onClick={() =>
                      setopenEditSectionModal({
                        status: true,
                        sectionData: sectionData,
                      })
                    }
                  >
                    <EditIcon />
                  </button>
                  <button
                    className="mx-3"
                    onClick={() =>
                      handleDeleteOrToggleSectionAndLeson(
                        sectionData,
                        "DELETE",
                        "القسم",
                        "sections"
                      )
                    }
                  >
                    <DeleteIcon />
                  </button>
                  <button
                    className="flex items-center justify-center   md:pl-4 py-2  md:py-4"
                    onClick={() =>
                      handleDeleteOrToggleSectionAndLeson(
                        sectionData,
                        "PATCH",
                        "القسم",
                        "sections"
                      )
                    }
                  >
                    <VisibilityIcon hiddin={sectionData?.hidden} />
                  </button>
                </div>
              </div>
              <div className="flex flex-wrap ">
                <h5 className="">{sectionData?.noOfStudents} طالب</h5>
                {!sectionData?.discountedPrice ? (
                  <h5 className="font-bold text_sucsess mx-3">
                    {sectionData?.price} ريال
                  </h5>
                ) : (
                  <div className="flex">
                    <h5 className="font-bold text_danger line-through mx-3 ">
                      {sectionData?.price} ريال
                    </h5>
                    <h5 className="font-bold text_sucsess">
                      {sectionData?.discountedPrice} ريال
                    </h5>
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className=" justify-between py-3 px-4  flex ">
                <h4 className="font-bold">الدروس</h4>
                <h4 className="font-bold w-20 text-center ">الرؤية</h4>
              </div>
              <div className="w-full shadow-md">
                {sectionData.lessons.length ? (
                  <>
                    {sectionData.lessons.map((leson, index) => (
                      <div
                        className={`w-full flex-col md:flex-row flex justify-between ${index != sectionData.lessons.length - 1 ? "border_buttom_greay" : ""}   `}
                        key={index}
                      >
                        <div className="flex-row items-center px-4 py-4  justify-between w-full   flex">
                          <div className="flex items-center flex-wrap   w-[70%]">
                            <h4 className="ml-4 break-words break-all ">
                              {leson.name}
                            </h4>
                            {leson.videoExists && (
                              <div className="mx-1">
                                <VideoIcon iconColor="greay" />
                              </div>
                            )}
                            {leson.pdfExists && (
                              <div className="mx-1">
                                <DocumentIcon iconColor="greay" />
                              </div>
                            )}
                            <div className="flex  items-center mr-auto edit_del">
                              <Link
                                className=""
                                to={`/edit-lesson/${prams.id}/${prams.name}/${sectionData.id}/${encodeURIComponent(sectionData.name)}/${leson.id}`}
                              >
                                <EditIcon />
                              </Link>
                              <button
                                className="mx-3"
                                onClick={() =>
                                  handleDeleteOrToggleSectionAndLeson(
                                    leson,
                                    "DELETE",
                                    "الدرس",
                                    "lessons"
                                  )
                                }
                              >
                                <DeleteIcon />
                              </button>
                            </div>
                          </div>
                          <div className="flex flex-nowrap w-fit my-3 md:my-0 mr-auto justify-center ">
                            <button
                              className="flex items-center justify-center ml-2  md:pl-4 py-2  md:py-4"
                              onClick={() =>
                                handleDeleteOrToggleSectionAndLeson(
                                  leson,
                                  "PATCH",
                                  "الدرس",
                                  "lessons"
                                )
                              }
                            >
                              <VisibilityIcon hiddin={leson.hidden} />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <h6 className="text_danger w-full text-center py-4">
                    لا يوجد دروس بعد
                  </h6>
                )}
              </div>
            </div>
            <Link
              className="text_secondary mt-6 h4 inline-block"
              to={`/add-lesson/${prams.id}/${prams.name}/${sectionData.id}/${encodeURIComponent(sectionData.name)}`}
            >
              + إضافة درس جديد
            </Link>
          </div>
        )}
      </div>
    </>
  );
}
CourseSection.propTypes = {
  sectionData: PropTypes.object,
  index: PropTypes.number,
  regetCourseDetailes: PropTypes.func,
};
