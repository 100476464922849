import React, { useEffect, useState } from "react";
import CustomInput from "../../components/reuseables/CustomInput";
import { Link, useNavigate } from "react-router-dom";
import CustomSubmitButton from "../../components/reuseables/CustomSubmitButton";
import { checkRequired } from "../../globalFunctions/validation";
import { myFeach, parceErr } from "../../globalFunctions/Api";
import PhoneInput from "../../components/reuseables/PhoneInput";
import DesafeLogo from "../../components/icons/DesafeLogo";
import DesafeTextLogo from "../../components/icons/DesafeTextLogo";
import Cookies from "js-cookie";
import SucsessMessage from "../../components/reuseables/SucsessMessage";
export default function Rejesteration() {
  const navigate = useNavigate();
  const [formData, setformData] = useState({
    data: { userType: "teacher", title: "" },
    submitLoader: { status: "" },
    errors: {},
  });
  const [titles, settitles] = useState({
    loader: { status: "" },
    data: [],
  });
  const [openFeedBackMessage, setopenFeedBackMessage] = useState({
    status: false,
  });
  const getTitles = () => {
    settitles({ loader: { status: "loading" } });
    const updatedFormData = { ...formData };
    delete updatedFormData.data.title;
    setformData({ ...updatedFormData });
    myFeach(`titles?gender=${formData.data.gender}`, "GET")
      .then(({ data }) => {
        settitles({ ...titles, loader: { status: "done" }, data: data.titles });
      })
      .catch((err) => {
        console.log(err);
        settitles({
          ...titles,
          loader: {
            status: "failed",
            text: parceErr(err)?.error
              ? parceErr(err).error
              : "فشل تحميل الألقاب",
          },
        });
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let requiredErrs = checkRequired(
      formData.data,
      ["name", "phone", "email", "gender", "password", "confirmPassword"],
      formData,
      setformData
    );
    if (requiredErrs === "sucsess") {
      setformData({ ...formData, submitLoader: { status: "loading" } });
      let finalObject = { ...formData.data };
      delete finalObject.confirmPassword;
      myFeach("signup", "POST", finalObject)
        .then(({ data }) => {
          Cookies.set("signupToken", data.signupToken);
          setformData({
            ...formData,
            submitLoader: { status: "done" },
            errors: {},
            submitErr: "",
          });
          setopenFeedBackMessage({
            status: true,
            isSucsess: true,
            message: "تم إرسال الكود على الواتساب",
          });
          setTimeout(() => {
            navigate(`/signup/otp/${formData.data.phone}`);
          }, 2000);
        })
        .catch((err) => {
          const myErr = JSON.parse(err.message);
          setformData({
            ...formData,
            loader: { status: "done" },
            errors: { ...myErr.errors },
            submitErr: myErr.error,
          });
        });
    }
  };
  useEffect(() => {
    formData.data.gender && getTitles();
  }, [formData.data.gender]);

  return (
    <main className="full_page bg_primary " style={{ paddingTop: "25px" }}>
      {openFeedBackMessage.status && (
        <SucsessMessage
          close={() => setopenFeedBackMessage({ status: false })}
          isSucsess={openFeedBackMessage?.isSucsess}
          message={openFeedBackMessage?.message}
        />
      )}
      <figure className="w-full m-auto flex justify-center items-center h-20 logo   ">
        <DesafeLogo />
        <div className="text_logo">
          <DesafeTextLogo />
        </div>
      </figure>
      <form
        className="m-auto  bg_greay large_rounded p-10   w-10/12 md:w-7/12"
        onSubmit={handleSubmit}
      >
        <h3 className="text-center font-bold text-2xl mb-12">سجل معنا</h3>
        <CustomInput
          width="100%"
          height={""}
          lable={"الاسم"}
          comment={""}
          placeHolder={""}
          required={true}
          name="name"
          setformData={setformData}
          formData={formData}
        />
        <div className="flex  justify-between mt-8 w-full flex-wrap ">
          <PhoneInput
            lable={"رقم الهاتف"}
            subLable="تأكد من وجود Whats app على الرقم"
            err={false}
            required={true}
            name="phone"
            setformData={setformData}
            formData={formData}
            comment="قم باختيار الدولة أولاً"
            containerClass="md:w-[48%] w-full md:pl-2 pl-0"
          />
          <CustomInput
            width="100%"
            height={""}
            lable={"البريد الإلكتروني"}
            comment={""}
            placeHolder={""}
            err={false}
            required={true}
            type="email"
            name="email"
            setformData={setformData}
            formData={formData}
            containerClass="md:w-[48%] w-full md:pr-2 pl-0 md:mt-4"
          />
        </div>
        <div className="flex  justify-between mt-8 w-full flex-wrap">
          <CustomInput
            width="100%"
            height={""}
            lable={"الجنس"}
            err={false}
            options={[
              { name: "", value: "" },
              { name: "ذكر", value: "ذكر" },
              { name: "أنثى", value: "أنثى" },
            ]}
            required={true}
            type="select"
            name="gender"
            setformData={setformData}
            formData={formData}
            containerClass="md:md:w-[48%] w-full md:pl-2 pl-0"
          />
          <CustomInput
            width="100%"
            height={""}
            lable={"اللقب"}
            err={titles?.err}
            type="select"
            name="title"
            disabled={formData.data.gender ? false : true}
            options={titles.data ? [{ name: "", id: "" }, ...titles.data] : ""}
            comment="يجب اختيار الجنس أولاً"
            loader={titles.loader}
            setformData={setformData}
            formData={formData}
            containerClass="md:md:w-[48%] w-full md:pr-2 pl-0 "
          />
        </div>
        <div className="flex  justify-between mt-8 mb-8 w-full flex-wrap ">
          <CustomInput
            width="100%"
            height={""}
            lable={"كلمة السر"}
            comment={
              "يجب أن يحتوي على 8 منهم حرف كبير وحرف صغير ورمز على الأقل "
            }
            placeHolder={""}
            err={false}
            required={true}
            type="password"
            name="password"
            setformData={setformData}
            formData={formData}
            containerClass="md:md:w-[48%] w-full md:pl-2 pl-0"
          />
          <CustomInput
            width="100%"
            height={""}
            lable={"تأكيد كلمة السر"}
            comment={"يجب أن يكونا متطابقان"}
            placeHolder={""}
            err={false}
            required={true}
            type="password"
            name="confirmPassword"
            setformData={setformData}
            formData={formData}
            disabled={formData.data?.password ? false : true}
            containerClass="md:md:w-[48%] w-full md:pr-2 pl-0"
          />
        </div>
        <CustomSubmitButton
          loader={formData.submitLoader}
          err={formData?.submitErr}
          width="100%"
          title="تسجيل"
        />
        <div className="flex justify-center w-full items-center  mt-8">
          <span> لديك حساب بالفعل ؟ </span>{" "}
          <Link to="/log-in" className="text_primary mx-2 block">
            تسجيل الدخول{" "}
          </Link>
        </div>
      </form>
    </main>
  );
}
