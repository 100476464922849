import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { myFeach, parceErr } from "../../globalFunctions/Api";
import ResultMessage from "../../components/reuseables/ResultMessage";
import studentImage from "../../assets/istockphoto-1045886560-612x612-1 1.png";
import { convertToDate } from "../../globalFunctions/TextModify";
import EditIcon from "../../components/icons/EditIcon";
import AddStudentNoteModal from "../../components/pagesComponents/students/AddStudentNoteModal";
import BlouckStudentModal from "../../components/pagesComponents/students/BlouckStudentModal";
import RemoveBlockIcon from "../../components/icons/removeBlockIcon";
import BlockIcon from "../../components/icons/BlockIcon";
import soonimg from "../../assets/13677898_5226583 2.png";
export default function Student() {
  const [studentDetailes, setstudentDetailes] = useState({
    loader: { status: "loading" },
    data: {},
  });
  const [openAddNoteModal, setopenAddNoteModal] = useState(false);
  const [openBlouckStudentModal, setopenBlouckStudentModal] = useState(false);
  const prames = useParams();
  const getStudentDetailes = () => {
    studentDetailes.loader.status !== "loading" &&
      setstudentDetailes({
        ...setstudentDetailes,
        loader: { status: "loading" },
      });
    myFeach(`students/${prames.id}`, "GET", {}, "token")
      .then(({ data }) => {
        setstudentDetailes({ loader: { status: "done" }, data: data.student });
      })
      .catch((err) => {
        let errText = parceErr(err)?.error
          ? parceErr(err)?.error
          : "فشل تحميل تفاصيل الطالب";
        setstudentDetailes({ loader: { status: "failed", text: errText } });
      });
  };
  useEffect(() => {
    getStudentDetailes();
  }, []);
  return (
    <main>
      {openAddNoteModal && (
        <AddStudentNoteModal
          currentNote={studentDetailes.data?.note}
          close={() => setopenAddNoteModal(false)}
          studentId={studentDetailes.data?.id}
          regetStudent={getStudentDetailes}
        />
      )}
      {openBlouckStudentModal && (
        <BlouckStudentModal
          close={() => setopenBlouckStudentModal(false)}
          studentId={studentDetailes.data?.id}
          studentName={studentDetailes.data?.name}
          regetStudent={getStudentDetailes}
          currentStatus={
            studentDetailes.data?.blocked ? "blocked" : "notBlocked"
          }
        />
      )}
      {studentDetailes.loader.status == "done" && (
        <div className="flex justify-between flex-wrap">
          {studentDetailes.data.blocked == true && (
            <div className="bg-red-100 my_rounded mb-6 py-4 px-4 flex justify-between w-full items-center flex-wrap ">
              <div className="flex items-center">
                <BlockIcon />
                <h5 className="text-red-500 w-full font-bold mx-3">
                  هذا الطالب تم حظره
                </h5>
              </div>
              <button
                onClick={() => setopenBlouckStudentModal(true)}
                className="bg-white my_rounded px-5 w-52 py-3 flex items-center "
              >
                <RemoveBlockIcon />
                إلغاء الحظر
              </button>
            </div>
          )}
          <div className="w-full student_card   bg-white shadow-md my_rounded flex flex-col items-center py-16 px-8 ">
            <div className="w-40 h-40  p-2 active__circle_border">
              <div
                className="imge_bg circle_rounded "
                style={{
                  backgroundImage: `url(${studentDetailes.data.image ? studentDetailes.data.image : studentImage})`,
                }}
              ></div>
            </div>
            <h3
              className={`font-bold text_active mt-6 ${/[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF]/.test(studentDetailes.data.name) ? "direction_right" : "direction_left"} `}
            >
              {studentDetailes.data.name}
            </h3>
            <h4 className="font-bold mt-3 break-words direction_left">
              {studentDetailes.data?.phone}
            </h4>
            <h4 className="font-normal mt-3 break-words ">
              {studentDetailes.data?.email}
            </h4>
            <h4 className="font-normal mt-3 break-words">
              {studentDetailes.data?.academicYear}
            </h4>
            <h4 className="font-normal mt-3 ">
              {studentDetailes.data?.age}عام
            </h4>
            <h4 className="font-normal mt-3 ">
              <span className="font-bold">start: </span>
              {convertToDate(studentDetailes.data?.enrollmentDate)}
            </h4>
            <div className="flex items-center justify-between w-full mt-6">
              <h4>ملحوظة:</h4>
              <button onClick={() => setopenAddNoteModal(true)}>
                <EditIcon />
              </button>
            </div>
            {studentDetailes.data.note && (
              <h5 className="mt-3 p-2 my_border w-full min-h-14 break-words">
                {studentDetailes.data.note}
              </h5>
            )}
            {studentDetailes.data.blocked == false && (
              <button
                onClick={() => setopenBlouckStudentModal(true)}
                className="mt-4 my_rounded px-4 py-2 border-red-500 border-2 text-red-500"
              >
                حظر الطالب
              </button>
            )}
          </div>
          <div className="w-full soon_card  md:h-screen  bg-white shadow-md my_rounded flex flex-col items-center justify-center">
            <div className="w-2/6 h-2/6">
              <img src={soonimg} className="block w-full h-auto" />
            </div>
          </div>
        </div>
      )}
      {studentDetailes.loader.status === "failed" && (
        <ResultMessage message={studentDetailes.loader.text} />
      )}
      {studentDetailes.loader.status == "loading" && (
        <div className="flex justify-between flex-wrap">
          <div className="w-full student_card  md:h-screen bg-white shadow-md my_rounded flex flex-col items-center py-16 px-4 ">
            <div className="w-40 h-40  p-2 circle_rounded bg_greay"></div>
            <div className="mt-10 w-4/6 h-10 bg_greay"></div>
            <div className="mt-10 w-4/6 h-10 bg_greay"></div>
            <div className="mt-10 w-4/6 h-10 bg_greay"></div>
          </div>
          <div className=" w-full soon_card  md:h-screen  bg-white shadow-md my_rounded flex flex-col"></div>
        </div>
      )}
    </main>
  );
}
