import React from "react";
import PropTypes from "prop-types";
import GroupIcon from "../../icons/GroupIcon";
import VideoIcon from "../../icons/VideoIcon";
import DocumentIcon from "../../icons/DocumentIcon";

export default function CoursePrompetCard({ cardDetailes }) {
  return (
    <div className="px-10 mb-4">
      <div className="w-fit bg_greay p-2 my_rounded flex flex-wrap md:flex-nowrap  ">
        <figure className="h-20 w-40 ml-2 mb-4 md:mb-0">
          <div
            className="imge_bg my_rounded"
            style={{
              backgroundImage: `url(${cardDetailes?.image})`,
            }}
          ></div>
        </figure>
        <div className="flex justify-between w-full flex-wrap">
          <div className="">
            <div className="flex flex-wrap ">
              <h5 className="font-bold">{cardDetailes?.name}</h5>
            </div>
            <div className="flex md:mt-4 flex-wrap mt-2 ">
              <div className="flex items-center ml-4 ">
                <GroupIcon />
                <h5 className="mx-2"> {cardDetailes?.noOfStudents}طالب</h5>
              </div>
              <div className="flex items-center ml-4 ">
                <VideoIcon />
                <h5 className="mx-2">{cardDetailes?.noOfVideos}فيديو </h5>
              </div>
              <div className="flex items-center ">
                <DocumentIcon />
                <h5 className="mx-2">{cardDetailes?.noOfPdfs}مستند </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
CoursePrompetCard.propTypes = {
  cardDetailes: PropTypes.object,
};
